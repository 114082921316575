<template>
  <v-container>
    <div :class="theSection.backgroundColor">
      <v-row>
        <v-col :cols="cols" v-for="section in sections" v-bind:key="section.id">
          <card-view :section="section"></card-view>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service";
import cardView from "@/views/Pages/Card-View";
import sectionMixin from "./sectionMixin";

export default {
  components: {
    "card-view": cardView,
  },

  created() {
    this.get();
  },

  data: () => ({
    cols: 3,
    cards: [],
    sections: [],
  }),

  mixins: [sectionMixin],

  methods: {
    get() {
      this.sections = []
      if (this.theSection != null && this.theSection.typeData.length > 0) {
        const data = JSON.parse(this.theSection.typeData);
        data.sectionIds.forEach((id) => {
          const url = "section/" + id;
          apiService.get(url, this.getSuccess, this.getError);
        });
        switch (data.sectionIds.length) {
          case 1:
            this.cols = 12;
            break;
          case 2:
            this.cols = 6;
            break;
          case 3:
            this.cols = 4;
            break;
          case 4:
            this.cols = 3;
            break;
          case 5:
          case 6:
            this.cols = 2;
            break;
          default:
            this.cols = 1;
        }
      }
    },

    getSuccess(result) {
      const section = result.data
      section.typeData = section.typeData.replace("false", "true")
      this.sections.push(section);
    },

    getError(error) {
      console.log("Error getting section: ", error);
    },

    update() {
      this.get();
    },
  },
};
</script>

<style>
.card-view {
  padding: 0px;
}
</style>