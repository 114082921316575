<template>
  <v-container>
    <v-row>
      <v-col cols="12" xl="2" lg="2" md="2" sm="6">
        <v-switch
          v-model="continuous"
          label="Continuous"
          @change="updateTypeData"
        ></v-switch>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="6">
        <v-switch
          v-model="cycle"
          label="Cycle"
          @change="updateTypeData"
        ></v-switch>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="6">
        <v-text-field
          v-model="interval"
          label="Interval"
          type="number"
          @change="updateTypeData"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="6">
        <v-text-field
          v-model="height"
          label="Height"
          type="number"
          @change="updateTypeData"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          show-select
          show-expand
          v-model="selected"
          :headers="headers"
          :items="images"
          @input="updateTypeData"
        >
          <template v-slot:expanded-item="{ item }">
            <td :colspan="headers.length">
              <v-card>
                <v-card-title>{{ item.title }}</v-card-title>
                <v-card-subtitle>{{ item.description }}</v-card-subtitle>
                <v-card-text>
                  <v-img :src="`data:image/png;base64,` + item.data"> </v-img>
                </v-card-text>
              </v-card>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service";
import sectionMixin from "./sectionMixin";

export default {
  created() {
    this.get();
  },

  data: () => ({
    continuous: true,
    cycle: true,
    headers: [
      {
        text: "Id",
        align: "end",
        groupable: false,
        sortable: true,
        value: "id",
      },
      {
        text: "Title",
        align: "start",
        groupable: false,
        sortable: true,
        value: "title",
      },
      {
        text: "Description",
        align: "start",
        groupable: false,
        sortable: true,
        value: "description",
      },
      {
        text: "Filename",
        align: "start",
        groupable: false,
        sortable: true,
        value: "filename",
      },
    ],
    height: 500,
    images: [],
    interval: 6000,
    selected: [],
  }),

  mixins: [sectionMixin],

  methods: {
    updateTypeData() {
      const imageIds = [];
      this.selected.forEach((image) => {
        imageIds.push(image.id);
      });
      const obj = {
        continuous: this.continuous,
        cycle: this.cycle,
        height: this.height,
        imageIds: imageIds,
        interval: this.interval,
      };
      this.theSection.typeData = JSON.stringify(obj);
      this.emitSection();
    },

    get() {
      apiService.get("image", this.getImagesSuccess, this.getImagesError);
    },

    getImagesSuccess(result) {
      this.images = result.data;
      if (this.theSection != null && this.theSection.typeData.length > 0) {
        const obj = JSON.parse(this.theSection.typeData);
        this.selected = [];
        obj.imageIds.forEach((id) => {
          const img = this.images.find((i) => i.id == id);
          if (img) {
            this.selected.push(img);
          }
        });
        this.continuous = obj.continuous;
        this.cycle = obj.cycle;
        this.height = obj.height;
        this.interval = obj.interval;
      }
    },

    getImagesError(error) {
      console.log("Error getting the images: ", error)
    },
  },
};
</script>

<style>
</style>