<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table
          show-select
          v-model="selected"
          :headers="headers"
          :items="cards"
          @input="updateTypeData"
        >
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service";
import sectionMixin from "./sectionMixin";

export default {
  created() {
    this.get();
  },

  data: () => ({
    cards: [],
    headers: [
      {
        text: "Section Id",
        align: "end",
        groupable: false,
        sortable: true,
        value: "id",
      },
      {
        text: "Title",
        align: "start",
        groupable: false,
        sortable: true,
        value: "title",
      },
      {
        text: "Subtitle",
        align: "start",
        groupable: false,
        sortable: true,
        value: "subtitle",
      },
      {
        text: "To",
        align: "start",
        groupable: false,
        sortable: true,
        value: "to",
      },
      {
        text: "Button Text",
        align: "start",
        groupable: false,
        sortable: true,
        value: "buttonText",
      },
    ],
    sections: [],
    selected: [],
  }),

  mixins: [ sectionMixin ],

  methods: {
    updateTypeData() {
      const data = {
        sectionIds: []
      }
      this.selected.forEach(card => {
        data.sectionIds.push(card.id)
      })
      this.theSection.typeData = JSON.stringify(data);
      this.emitSection()
    },

    get() {
      if (this.theSection != null && this.theSection.typeData.length > 0) {
        this.card = JSON.parse(this.theSection.typeData);
      }
      apiService.get("section", this.getSectionSuccess, this.getSectionError);
    },

    getSectionSuccess(result) {
      const array = result.data
      let data = {}
      if (this.theSection != null && this.theSection.typeData.length > 0) {
        data = JSON.parse(this.theSection.typeData);
      }
      array.forEach(section => {
        if(section.type == 5) {
          this.sections.push(section)
          const card = JSON.parse(section.typeData)
          card.id = section.id
          this.cards.push(card)
          if(data.sectionIds.includes(card.id)){
            this.selected.push(card)
          }
        }
      });

      console.log(this.sections)
    },

    getSectionError(error) {
      console.log("Error getting TBD: ", error);
    },
  },
};
</script>

<style>
</style>