<template>
  <v-container v-if="card.show">
    <div :class="theSection.backgroundColor">
      <v-row class="card-view">
        <v-col cols="12">
          <v-card>
            <v-card-title>{{ card.title }}</v-card-title>
            <v-card-subtitle>{{ card.subtitle }}</v-card-subtitle>
            <v-card-text>
              <v-row>
                <v-img class="card-image"
                  v-if="card.imageId != 0"
                  :src="`data:image/png;base64,` + image.data"
                >
                </v-img>
              </v-row>
              <v-row>
                <div class="card-text" v-if="card.text.length > 0">
                  {{ card.text }}
                </div>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text :to="card.to">
                <v-icon>{{ card.icon }}</v-icon
                >{{ card.buttonText }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service";
import sectionMixin from "./sectionMixin";

export default {
  created() {
    this.get();
  },

  data: () => ({
    card: {
      title: "",
      imageId: 0,
      subtitle: "",
      to: "",
      icon: "",
      buttonText: "",
      text: "",
      show: false,
    },
    image: {
      id: 0,
      data: "",
    },
  }),

  mixins: [sectionMixin],

  methods: {
    get() {
      if (this.theSection != null && this.theSection.typeData.length > 0) {
        this.card = JSON.parse(this.theSection.typeData);
        const url = "image/" + this.card.imageId;
        apiService.get(url, this.getSuccess, this.getError);
      }
    },

    getSuccess(result) {
      this.image = result.data;
    },

    getError(error) {
      console.log("Error getting image: ", error);
    },

    update() {
      this.get();
    },
  },
};
</script>

<style>
.card-view {
  padding: 10px;
}
.card-text {
  padding: 10px;
}
.card-image {
  max-height: 100%;
  max-width: 100%;
  padding: 10px;
  overflow: hidden;
}
</style>