<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <vue-editor
          v-model="theSection.markup"
          @text-change="emitSection"
        ></vue-editor>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { VueEditor } from "vue2-editor";
import sectionMixin from "./sectionMixin";

export default {

  components: {
    VueEditor,
  },

  mixins: [ sectionMixin ],
};

</script>

<style>

</style>