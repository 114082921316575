
export default {
  props: {
    section: Object,
  },

  created() {
    this.updateSection();
  },
  
  data: () => ({
    theSection: {
      id: 0,
      backgroundColor: "",
      order: 1,
      markup: "",
      page: null,
      pageId: 0,
      type: 1,
      includeEstimate: 0,
      typeData: ""
    },
  }),

  methods: {
    emitSection() {
      this.$emit('update', this.theSection)
    },

    setSection(section) {
      this.theSection = section;
      this.update()
    },

    update(){},

    updateSection() {
      if (this.section) {
        this.theSection = this.section
      }
    },
  },  

  watch: {
    section: function () {
      this.updateSection();
    },
  },
};
