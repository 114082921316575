<template>
  <v-container>
    <v-row>
      <v-col cols="12" xl="2" lg="2" md="2" sm="6">
        <v-text-field
          v-model="card.title"
          label="Title"
          type="text"
          @change="updateTypeData"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="6">
        <v-select
          v-model="card.imageId"
          :items="images"
          item-value="id"
          label="Image"
          @change="updateTypeData"
        >
          <template v-slot:selection="{ item }">
            <div>{{ item.title }}</div>
            <img
              class="image"
              :src="`data:image/png;base64,` + item.data"
              height="48"
              width="48"
            />
          </template>
          <template v-slot:item="{ item }">
            <div>{{ item.title }}</div>
            <img class="image" :src="`data:image/png;base64,` + item.data" />
          </template>
        </v-select>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="5">
        <v-text-field
          v-model="card.to"
          label="To"
          type="text"
          @change="updateTypeData"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="1" lg="1" md="1" sm="5">
        <v-text-field
          v-model="card.icon"
          label="Icon"
          type="text"
          @change="updateTypeData"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="1" lg="1" md="1" sm="2">
        <v-icon>{{ card.icon }}</v-icon>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="5">
        <v-text-field
          v-model="card.buttonText"
          label="Button Text"
          type="text"
          @change="updateTypeData"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="6">
        <v-switch
          v-model="card.show"
          label="Show"
          @change="updateTypeData"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="12" lg="12" md="12" sm="12">
        <v-text-field
          v-model="card.subtitle"
          label="Sub-Title"
          type="text"
          @change="updateTypeData"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="12" lg="12" md="12" sm="12">
        <v-text-field
          v-model="card.text"
          label="Text"
          type="text"
          @change="updateTypeData"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service";
import sectionMixin from "./sectionMixin";

export default {
  created() {
    this.get();
  },

  data: () => ({
    card: {
      title: "",
      imageId: 0,
      subtitle: "",
      to: "",
      icon: "",
      buttonText: "",
      text: "",
      show: false,
    },
    images: [],
  }),

  mixins: [ sectionMixin ],

  methods: {
    updateTypeData() {
      this.theSection.typeData = JSON.stringify(this.card);
      this.emitSection();
    },

    get() {
      if (this.theSection != null && this.theSection.typeData.length > 0) {
        this.card = JSON.parse(this.theSection.typeData);
      }
      apiService.get("image", this.getImagesSuccess, this.getImagesError);
    },

    getImagesSuccess(result) {
      this.images = result.data;
    },

    getImagesError(error) {
      console.log("Error getting images: ", error);
    },
  },
};
</script>

<style>
</style>