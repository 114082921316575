<template>
  <v-container>
    <v-row>
      <v-col cols="12" xl="3" lg="3" md="3" sm="12">
        <v-select
          v-model="location"
          :items="locations"
          item-text="text"
          item-value="id"
          label="Location"
          @change="updateTypeData"
        ></v-select>
      </v-col>
      <v-col cols="12" xl="1" lg="1" md="1" sm="12">
        <v-text-field
          v-model="imageColumns"
          label="Image Columns"
          type="number"
          @change="updateTypeData"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="8" lg="8" md="8" sm="12">
        <v-select
          v-model="imageId"
          :items="images"
          item-value="id"
          label="Image"
          @change="updateTypeData"
        >
          <template v-slot:selection="{ item }"
            >
            <div>{{ item.title }}</div>
            <img class="image"
              :src="`data:image/png;base64,` + item.data"
              height="48"
              width="48"
            />
          </template>
          <template v-slot:item="{ item }"
            >            
            <div>{{ item.title }}</div>
            <img class="image" :src="`data:image/png;base64,` + item.data" />
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <vue-editor v-model="theSection.markup" @text-change="emitSection"></vue-editor>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import apiService from "@/_services/api-service";
import sectionMixin from "./sectionMixin";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor,
  },

  created() {
    this.get();
  },

  data: () => ({
    imageColumns: 2,
    imageId: 0,
    images: [],
    location: 1,
    locations: [
      {
        id: 1,
        text: "Left",
      },
      {
        id: 2,
        text: "Right",
      },
    ],
  }),

  mixins: [ sectionMixin ],

  methods: {
    updateTypeData() {
      const obj = {
        imageId: this.imageId,
        imageColumns: this.imageColumns,
        imageLocation: this.location,
      }
      this.theSection.typeData = JSON.stringify(obj)
      this.emitSection()
    },

    get() {
      apiService.get("image", this.getImagesSuccess, this.getImagesError);
    },

    getImagesSuccess(result) {
      this.images = result.data;
    },

    getImagesError(error) {
      console.log("Error getting the images: ", error)
    },
  },

  mounted() {
    if(this.theSection != null && this.theSection.typeData.length > 0) {
      const obj = JSON.parse(this.theSection.typeData)
      this.imageId = obj.imageId
      this.location = obj.imageLocation
      this.imageColumns = obj.imageColumns
    }
  },

}
</script>

<style>

</style>