<template>
  <v-container>
    <div :class="theSection.backgroundColor">
      <v-row v-if="imageLocation == 1">
        <v-col
          cols="12"
          :xl="imageColumns"
          :lg="imageColumns"
          :md="imageColumns"
          sm="12"
        >
          <img
            class="view-image"
            :src="`data:image/png;base64,` + image.data"
          />
        </v-col>
        <v-col
          cols="12"
          :xl="markupColumns"
          :lg="markupColumns"
          :md="markupColumns"
          sm="12"
        >
          <div class="markup-right" v-html="theSection.markup"></div>
        </v-col>
      </v-row>
      <v-row v-if="imageLocation == 2">
        <v-col
          cols="12"
          :xl="markupColumns"
          :lg="markupColumns"
          :md="markupColumns"
          sm="12"
        >
          <div class="markup-left" v-html="theSection.markup"></div>
        </v-col>
        <v-col
          cols="12"
          :xl="imageColumns"
          :lg="imageColumns"
          :md="imageColumns"
          sm="12"
        >
          <img
            class="view-image"
            :src="`data:image/png;base64,` + image.data"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service";
import sectionMixin from "./sectionMixin";

export default {
  data: () => ({
    image: {
      id: 0,
      data: "",
    },
    imageColumns: 2,
    imageId: 0,
    imageLocation: 1,
    markupColumns: 10,
  }),

  mixins: [sectionMixin],

  methods: {
    get() {
      const url = "image/" + this.imageId;
      apiService.get(url, this.getSuccess, this.getError);
    },

    getSuccess(result) {
      this.image = result.data;
    },

    getError(error) {
      console.log("Error getting the Image: ", error);
    },

    update() {
      if (this.theSection != null && this.theSection.typeData.length > 0) {
        const obj = JSON.parse(this.theSection.typeData);
        this.imageId = obj.imageId;
        this.imageLocation = obj.imageLocation;
        this.imageColumns = obj.imageColumns;
        this.markupColumns = 12 - this.imageColumns;
        this.get();
      }
    },
  },

  mounted() {
    this.update();
  },
};
</script>

<style>
.markup-right {
  padding-right: 10px;
}
.markup-left {
  padding-left: 20px;
}
.view-image {
  max-height: 100%;
  max-width: 100%;
  padding: 10px;
}
</style>