<template>
  <v-container>
    <div :class="theSection.backgroundColor">
      <v-carousel
        :continuous="continuous"
        :cycle="cycle"
        :interval="interval"
        :height="height"
      >
        <v-carousel-item v-for="(image, i) in images" :key="i">
          <v-card :height="height">
            <v-card-title>{{ image.title }}</v-card-title>
            <v-card-subtitle>{{ image.description }}</v-card-subtitle>
            <v-card-text class="image-parent">
              <v-img
                class="carousel-image"
                :src="`data:image/png;base64,` + image.data"
              >
              </v-img>
            </v-card-text>
          </v-card>
        </v-carousel-item>
      </v-carousel>
    </div>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service";
import sectionMixin from "./sectionMixin";

export default {

  data: () => ({
    continuous: true,
    cycle: true,
    height: 500,
    imageIds: [],
    images: [],
    interval: 6000,
  }),

  mixins: [sectionMixin],

  methods: {
    get() {
      this.images = []
      this.imageIds.forEach((id) => {
        const url = "image/" + id;
        apiService.get(url, this.getSuccess, this.getError);
      });
    },

    getSuccess(result) {
      this.images.push(result.data);
    },

    getError(error) {
      console.log("Error getting image: ", error);
    },

    update() {
      if (this.theSection != null && this.theSection.typeData.length > 0) {
        const obj = JSON.parse(this.theSection.typeData);
        this.imageIds = []
        obj.imageIds.forEach((id) => {
          this.imageIds.push(id);
        });
        this.continuous = obj.continuous;
        this.cycle = obj.cycle;
        this.height = obj.height;
        this.interval = obj.interval;
        this.get()
      }
    },
  },

  mounted() {
    this.update()
  },
};

</script>

<style>
@import "~vue2-editor/dist/vue2-editor.css";
.image-parent {
  position: relative;
  text-align: center;
  height: 100%;
  width: 96%;
  overflow: hidden;
  margin-left: 20px;
}
.carousel-image {
  position: absolute;
  top: 30%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>