<template>
  <v-container>
    <div :class="section.backgroundColor" v-html="theSection.markup"></div>
  </v-container>
</template>

<script>
import sectionMixin from "./sectionMixin";

export default {

  mixins: [ sectionMixin ],

}
</script>

<style>
</style>