<template>
  <v-container>
    <v-form v-model="valid">
      <v-card outlined hover loading>
        <v-card-title>
          <v-row>
            <v-spacer></v-spacer>
            <div>Request Your Free Estimate</div>
            <v-spacer></v-spacer>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-text-field color="accent"
            class="estimate-input"
            :disabled="disable"
            dense
            label="Name"
            v-model="estimate.name"
            required
            outlined
            filled
            :rules="rulesName"
            hint="Name"
          />
          <v-text-field color="accent"
            class="estimate-input"
            :disabled="disable"
            dense
            label="Address"
            v-model="estimate.address"
            outlined
            filled
            hint="Address"
          />
          <v-text-field color="accent"
            class="estimate-input"
            :disabled="disable"
            dense
            label="City"
            v-model="estimate.city"
            outlined
            filled
            hint="City"
          />
          <v-text-field color="accent"
            class="estimate-input"
            :disabled="disable"
            dense
            label="Zipcode"
            v-model="estimate.zipcode"
            outlined
            filled
            hint="Zipcode"
          />
          <v-text-field color="accent"
            class="estimate-input"
            :disabled="disable"
            dense
            label="Email"
            prepend-icon="mdi-at"
            v-model="estimate.email"
            outlined
          />
          <v-text-field color="accent"
            class="estimate-input"
            :disabled="disable"
            dense
            prepend-icon="mdi-card-account-phone"
            label="Phone"
            v-model="estimate.phone"
            outlined
          />
          <v-select color="accent"
            class="estimate-input"
            :disabled="disable"
            v-model="selectedProjects"
            :items="projects"
            attach
            chips
            dense
            deletable-chips
            label="Project Type"
            multiple
          ></v-select>
          <v-textarea color="accent"
            class="estimate-input"
            dense
            :disabled="disable"
            label="Message"
            v-model="estimate.message"
            outlined
          />
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="8">
              <v-text-field
                class="estimate-input"
                :disabled="disable"
                dense
                prepend-icon="mdi-lock"
                :label="verifyLabel"
                v-model="answer"
                outlined
              />
            </v-col>
            <v-col cols="4">
              <v-btn
                class="submit-button"
                outlined
                @click="onSubmit"
                :disabled="disable"
                ><v-icon>mdi-save</v-icon>Submit</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service";
import registry from "@/_services/registry-service";
import store from "@/store";

export default {
  name: "estimate",

  created() {
    this.verify[0] = Math.floor(Math.random() * 3) + 1;
    this.verify[1] = Math.floor(Math.random() * 3) + 1;
    this.verifyLabel = this.verifyLabel =
      "Verify " + this.verify[0] + " + " + this.verify[1];
    const projects = registry.getValue("estimate_projects").split(",");
    if (projects.length > 0) {
      this.projects = projects;
    }
  },

  data: () => ({
    answer: "",
    disable: false,
    estimate: {
      id: 0,
      name: "",
      address: "",
      city: "",
      estimateDate: new Date(),
      zipcode: "",
      message: "",
      email: "",
      phone: "",
      projectType: "",
    },
    projects: ["Missing estimate_projects"],
    rulesName: [
      (v) => !!v || "Name is required",
      (v) => (v || "").length <= 255 || "Name must be 255 characters or less",
    ],
    selectedProjects: [],
    valid: false,
    verify: [0, 0],
    verifyLabel: "",
  }),

  methods: {
    onSubmit() {
      this.disable = true;
      const total = this.verify[0] + this.verify[1];
      const result = {
        statusCode: 200,
        errors: [],
      };
      if (this.estimate.name.length == 0) {
        (result.statusCode = 400), result.errors.push("Name is required");
      }
      if (this.estimate.email.length == 0 && this.estimate.phone.length == 0) {
        (result.statusCode = 400),
          result.errors.push("Email or Phone is required");
      }
      if (this.answer != total) {
        result.statusCode = 400;
        result.errors.push("Verify total does not add up");
      }
      if (result.statusCode == 200) {
        this.selectedProjects.forEach((type) => {
          if (this.estimate.projectType.length > 0) {
            this.estimate.projectType += ", ";
          }
          this.estimate.projectType += type;
        });
        console.log("Estimate: ", this.estimate);
        result.errors.push("Saving your request");
        apiService.post(
          "estimates",
          this.estimate,
          this.postSuccess,
          this.postError
        );
      } else {
        result.errors.push(" ");
      }
    },

    postSuccess() {
      const notification = {
        title: "Estimate Saved",
        status: "success",
        messages: ["Your estimate was saved, check you email."],
      };
      store.commit("setNotification", notification);
      this.selectedProjects = [];
      this.estimate = {
        id: 0,
        name: "",
        address: "",
        city: "",
        estimateDate: new Date(),
        zipcode: "",
        message: "",
        email: "",
        phone: "",
        projectType: "",
      };
      this.disable = false;
    },

    postError() {
      const notification = {
        title: "Estimate Failed",
        status: "error",
        messages: ["Your estimate failed to send, please try again"],
      };
      store.commit("setNotification", notification);
      this.disable = false;
    },
  },

  store,
};
</script>

<style lang="scss" scoped>
.v-label--active {
  color: var(--v-accent-base) !important;
  caret-color: var(--v-accent-base) !important;
}

.estimate-input {
  color: var(--v-accent-base) !important;
  caret-color: var(--v-accent-base) !important;
}
</style>
