<template>
  <v-container>
    <v-row>
      <v-col cols="12" xl="2" lg="2" md="6" sm="12">
        <v-select
          v-model="location"
          :items="locations"
          item-text="text"
          item-value="id"
          label="Location"
          @change="updateTypeData"
        ></v-select>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="6" sm="12">
        <v-text-field
          v-model="videoColumns"
          label="Video Columns"
          type="number"
          @change="updateTypeData"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="6" sm="12">
        <v-text-field
          v-model="height"
          label="Height"
          type="number"
          @change="updateTypeData"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="6" sm="12">
        <v-text-field
          v-model="width"
          label="Width"
          type="number"
          @change="updateTypeData"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="12" sm="12">
        <v-select
          v-model="videoId"
          :items="videos"
          item-value="id"
          item-text="title"
          label="Video"
          @change="updateTypeData"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <vue-editor
          v-model="theSection.markup"
          @text-change="emitSection"
        ></vue-editor>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service";
import sectionMixin from "./sectionMixin";
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor,
  },

  created() {
    this.get();
  },

  data: () => ({
    height: 200,
    location: 1,
    locations: [
      {
        id: 1,
        text: "Left",
      },
      {
        id: 2,
        text: "Right",
      },
    ],
    videoColumns: 2,
    videoId: 0,
    videos: [],
    width: 200,
  }),

  mixins: [sectionMixin],

  methods: {
    get() {
      apiService.get("video", this.getVideosSuccess, this.getVideosError);
    },

    getVideosSuccess(result) {
      this.videos = result.data;
      this.parse()
    },

    getVideosError(error) {
      console.log("Error getting the videos: ", error);
    },

    onSelectedImageChanged() {
      const img = {
        videoId: this.videoId,
      };
      this.theSection.typeData = JSON.stringify(img);
      this.emitSection();
    },

    parse() {
      if (this.theSection != null && this.theSection.typeData.length > 0) {
        const obj = JSON.parse(this.theSection.typeData);
        this.height = obj.height;
        this.videoId = obj.videoId;
        this.videoColumns = obj.videoColumns;
        this.location = obj.videoLocation;
        this.width = obj.width;
      }
    },

    updateTypeData() {
      const obj = {
        height: this.height,
        videoId: this.videoId,
        videoColumns: this.videoColumns,
        videoLocation: this.location,
        width: this.width,
      };
      this.theSection.typeData = JSON.stringify(obj);
      this.emitSection();
    },
  },

  mounted() {
    this.parse()
  },
};
</script>

<style>
.image {
  margin-left: 20px;
  max-height: 100%;
  max-width: 100%;
}
</style>