<template>
  <v-container>
    <div :class="theSection.backgroundColor">
      <v-row>
        <v-col cols="12">
          <img
            class="view-image"
            :src="`data:image/png;base64,` + image.data"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service";
import sectionMixin from "./sectionMixin";

export default {
  mounted() {
    this.get();
  },

  data: () => ({
    image: {
      id: 0,
      data: "",
    },
    videoSource: [],
  }),

  mixins: [sectionMixin],

  methods: {
    get() {
      if (this.theSection != null && this.theSection.typeData.length > 0) {
        const obj = JSON.parse(this.theSection.typeData);
        const url = "image/" + obj.imageId;
        apiService.get(url, this.getSuccess, this.getError);
      }
    },

    getSuccess(result) {
      this.image = result.data;
    },

    getError(error) {
      console.log("Error getting the Image: ", error);
    },

    update() {
      this.get();
    },
  },
};
</script>

<style>
.view-image {
  max-height: 100%;
  max-width: 100%;
}
</style>