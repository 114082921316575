<template>
  <v-container>
    <v-row>
      <v-col cols="12" xl="9" lg="9" md="9" sm="12">
        <v-select
          v-model="imageId"
          :items="images"
          item-value="id"
          label="Image"
          @change="onSelectedImageChanged"
        >
          <template v-slot:selection="{ item }"
            >
            <div>{{ item.title }}</div>
            <img class="image"
              :src="`data:image/png;base64,` + item.data"
              height="48"
              width="48"
            />
          </template>
          <template v-slot:item="{ item }"
            >            
            <div>{{ item.title }}</div>
            <img class="image" :src="`data:image/png;base64,` + item.data" />
          </template>
        </v-select>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service";
import sectionMixin from "./sectionMixin";

export default {

  created() {
    this.get();
  },

  data: () => ({
    imageId: 0,
    images: [],
  }),

  mixins: [ sectionMixin ],

  methods: {
    get() {
      apiService.get("image", this.getImagesSuccess, this.getImagesError);
    },

    getImagesSuccess(result) {
      const items = result.data
      this.images = [];
      items.forEach(image => {
        if(image.type == "image/jpeg" || image.type == "image/png") {
          this.images.push(image)
        }
      });
      if(this.theSection != null && this.theSection.typeData.length > 0) {
        const obj = JSON.parse(this.theSection.typeData)
        this.imageId = obj.imageId
      }
    },

    getImagesError(error) {
      console.log("Error getting the images: ", error)
    },

    onSelectedImageChanged() {
      const img = {
        imageId: this.imageId,
      }
      this.theSection.typeData = JSON.stringify(img)
      this.emitSection()
    },
  },
};
</script>

<style>
.image {
  margin-left: 20px;
  max-height: 100%;
  max-width: 100%;
}
</style>