<template>
  <v-container>
    <div :class="theSection.backgroundColor">
      <v-row v-if="videoLocation == 1">
        <v-col
          cols="12"
          :xl="videoColumns"
          :lg="videoColumns"
          :md="videoColumns"
          sm="12"
        >
          <video
            v-bind:id="videoControl"
            controls
            :height="videoHeight"
            :width="videoWidth"
          />
        </v-col>
        <v-col
          cols="12"
          :xl="markupColumns"
          :lg="markupColumns"
          :md="markupColumns"
          sm="12"
        >
          <div class="markup-right" v-html="theSection.markup"></div>
        </v-col>
      </v-row>
      <v-row v-if="videoLocation == 2">
        <v-col
          cols="12"
          :xl="markupColumns"
          :lg="markupColumns"
          :md="markupColumns"
          sm="12"
        >
          <div class="markup-left" v-html="theSection.markup"></div>
        </v-col>
        <v-col
          cols="12"
          :xl="videoColumns"
          :lg="videoColumns"
          :md="videoColumns"
          sm="12"
        >
          <video
            v-bind:id="videoControl"
            controls
            :height="videoHeight"
            :width="videoWidth"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import apiService from "@/_services/api-service";
import convert from "@/_services/convert-service";
import sectionMixin from "./sectionMixin";

export default {
  data: () => ({
    videoColumns: 2,
    videoId: 0,
    videoLocation: 1,
    markupColumns: 10,
    videoHeight: 200,
    videoWidth: 200,
    videoControl: 'video-',
  }),

  mixins: [sectionMixin],

  methods: {
    get() {
      const url = "video/download/" + this.videoId;
      apiService.get(url, this.getSuccess, this.getError);
    },

    getSuccess(result) {
      const buffer = convert.blobToByteArray(result.data);
      this.set(buffer);
    },

    getError(error) {
      console.log("Error getting the Image: ", error);
    },

    set(contents) {
      var video = document.getElementById(this.videoControl);
      if (video) {
        var blob = new Blob([contents]);
        video.src = URL.createObjectURL(blob);
        console.log("Contents: ", contents);
        video.play();
      }
    },

    update() {
      if (this.theSection != null && this.theSection.typeData.length > 0) {
        const obj = JSON.parse(this.theSection.typeData);
        this.videoColumns = obj.videoColumns;
        this.videoId = obj.videoId;
        this.videoLocation = obj.videoLocation;
        this.videoHeight = obj.height;
        this.videoWidth = obj.width;
        this.markupColumns = 12 - this.videoColumns;
        this.videoControl = 'video-' + this.videoId
        this.get();
      }
    },
  },

  mounted() {
    this.update();
  },
};
</script>

<style>
.markup-right {
  padding-right: 10px;
}
.markup-left {
  padding-left: 20px;
}
.view-image {
  max-height: 100%;
  max-width: 100%;
}
</style>