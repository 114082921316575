<template>
  <v-container>
    <h1>Section</h1>
    <v-row>
      <v-col cols="12" xl="3" lg="3" md="3" sm="12">
        <v-select
          v-model="section.type"
          :items="types"
          item-text="text"
          item-value="id"
          label="Type"
        ></v-select>
      </v-col>
      <v-col cols="12" xl="1" lg="1" md="1" sm="12">
        <v-text-field
          v-model="section.order"
          label="Order"
          type="number"
        ></v-text-field>
      </v-col>
      <v-col cols="12" xl="3" lg="3" md="3" sm="12">
        <v-select
          v-model="section.backgroundColor"
          :items="classes"
          item-text="text"
          item-value="id"
          label="Class"
        ></v-select>
      </v-col>
      <v-col cols="12" xl="2" lg="2" md="2" sm="12">
        <v-checkbox
          v-model="section.includeEstimate"
          label="Include Estimate"
        ></v-checkbox>
      </v-col>
      <v-spacer></v-spacer>
      <v-btn outlined class="button" @click="onCancel()">
        <v-icon left>mdi-cancel</v-icon>Cancel
      </v-btn>
      <v-btn outlined class="button" @click="onSave()">
        <v-icon left>mdi-content-save</v-icon>Save
      </v-btn>
    </v-row>
    <!-- Row for the editor -->
    <v-row>
      <text-section
        v-if="section.type == 1"
        :section="section"
        @update="onUpdateSection"
      ></text-section>
      <image-section
        v-if="section.type == 2"
        :section="section"
        @update="onUpdateSection"
      ></image-section>
      <image-text-section
        v-if="section.type == 3"
        :section="section"
        @update="onUpdateSection"
      ></image-text-section>
      <carousel-section
        v-if="section.type == 4"
        :section="section"
        @update="onUpdateSection"
      ></carousel-section>
      <card-section
        v-if="section.type == 5"
        :section="section"
        @update="onUpdateSection"
      ></card-section>
      <cards-section
        v-if="section.type == 6"
        :section="section"
        @update="onUpdateSection"
      ></cards-section>
      <video-section
        v-if="section.type == 7"
        :section="section"
        @update="onUpdateSection"
      ></video-section>
    </v-row>
    <!-- Row for the view -->
    <v-row v-if="section.includeEstimate">
      <v-col cols="12" xl="8" lg="8" md="8" sm="12">
        <text-view
          ref="textView"
          v-if="section.type == 1"
          :section="section"          
        ></text-view>
        <image-view
          ref="imageView"
          v-if="section.type == 2"
          :section="section"
        ></image-view>
        <image-text-view
          ref="imageTextView"
          v-if="section.type == 3"
          :section="section"
        ></image-text-view>
        <carousel-view
          ref="carouselView"
          v-if="section.type == 4"
          :section="section"
        ></carousel-view>
        <card-view
          ref="cardView"
          v-if="section.type == 5"
          :section="section"
        ></card-view>
        <cards-view
          ref="cardsView"
          v-if="section.type == 6"
          :section="section"
        ></cards-view>
        <video-view
          ref="videoView"
          v-if="section.type == 7"
          :section="section"
        ></video-view>
      </v-col>
      <v-col cols="12" xl="4" lg="4" md="4" sm="12">
        <estimate></estimate>
      </v-col>
    </v-row>
    <v-row v-if="!section.includeEstimate">
      <text-view
        ref="textView"
        v-if="section.type == 1"
        :section="section"
        :class="section.backgroundColor"
      ></text-view>
      <image-view
        ref="imageView"
        v-if="section.type == 2"
        :section="section"
      ></image-view>
      <image-text-view
        ref="imageTextView"
        v-if="section.type == 3"
        :section="section"
      ></image-text-view>
      <carousel-view
        ref="carouselView"
        v-if="section.type == 4"
        :section="section"
      ></carousel-view>
      <card-view
        ref="cardView"
        v-if="section.type == 5"
        :section="section"
      ></card-view>
      <cards-view
        ref="cardsView"
        v-if="section.type == 6"
        :section="section"
      ></cards-view>
      <video-view
        ref="videoView"
        v-if="section.type == 7"
        :section="section"
      ></video-view>
    </v-row>
  </v-container>
</template>

<script>
// Sections Types to build
//
//  1 - Text
//  2 - Image
//  3 - Text & Image
//  4 - Carousel
//  5 - Card
//  6 - Multiple Cards
//  7 - Video

import apiService from "@/_services/api-service";
import estimate from "@/components/controls/Estimate";
import cardSection from "./Card-Section";
import cardView from "./Card-View";
import cardsSection from "./Cards-Section";
import cardsView from "./Cards-View";
import carouselSection from "./Carousel-Section";
import carouselView from "./Carousel-View";
import imageSection from "./Image-Section";
import imageTextSection from "./ImageText-Section";
import imgTextView from "./ImageText-View";
import imageView from "./Image-View";
import router from "@/router";
import store from "@/store";
import textSection from "./Text-Section";
import textView from "./Text-View";
import videoSection from "./Video-Section";
import videoView from "./Video-View";

export default {
  components: {
    "card-section": cardSection,
    "card-view": cardView,
    "cards-section": cardsSection,
    "cards-view": cardsView,
    "carousel-section": carouselSection,
    "carousel-view": carouselView,
    "text-section": textSection,
    "text-view": textView,
    "image-section": imageSection,
    "image-view": imageView,
    "image-text-section": imageTextSection,
    "image-text-view": imgTextView,
    "video-section": videoSection,
    "video-view": videoView,
    estimate,
  },

  created() {
    this.get();
  },

  data: () => ({
    classes: [
      {
        id: "background-light-5",
        text: "Lighten 5",
      },
      {
        id: "background-light-4",
        text: "Lighten 4",
      },
      {
        id: "background-light-3",
        text: "Lighten 3",
      },
      {
        id: "background-light-2",
        text: "Lighten 2",
      },
      {
        id: "background-light-1",
        text: "Lighten 1",
      },
      {
        id: "",
        text: "Primary Background",
      },
      {
        id: "background-dark-1",
        text: "Darken 1",
      },
      {
        id: "background-dark-2",
        text: "Darken 2",
      },
      {
        id: "background-dark-3",
        text: "Darken 3",
      },
      {
        id: "background-dark-4",
        text: "Darken 4",
      },
    ],
    types: [
      {
        id: 1,
        text: "Text",
      },
      {
        id: 2,
        text: "Image",
      },
      {
        id: 3,
        text: "Text & Image",
      },
      {
        id: 4,
        text: "Carousel",
      },
      {
        id: 5,
        text: "Card",
      },
      {
        id: 6,
        text: "Cards",
      },
      {
        id: 7,
        text: "Video",
      },
    ],
    section: {
      id: 0,
      backgroundColor: "",
      order: 1,
      markup: "",
      page: null,
      pageId: 0,
      type: 1,
      includeEstimate: 0,
      typeData: ""
    },
    selectedImage: null,
  }),

  methods: {
    get() {
      if (this.$route.params.id > 0) {
        const url = "section/" + this.$route.params.id;
        apiService.get(url, this.getSuccess, this.getError);
      }
    },

    getSuccess(result) {
      if (result.data.typeData == null) {
        result.data.typeData = "";
      }
      this.onUpdateSection(result.data)
    },

    getError(error) {
      const notification = {
        title: "Error getting the section",
        error: error,
      };
      store.commit("setResponseError", notification);
    },

    onCancel() {
      router.go(-1);
    },

    onSave() {
      if (this.section.pageId == 0) {
        this.section.pageId = store.getters.getPageId;
      }
      console.log("PageId: ", this.section.pageId);
      console.log("Section", this.section);
      let url = "section";
      if (this.section.id > 0) {
        url += "/" + this.section.id;
        apiService.put(url, this.section, this.saveSuccess, this.saveError);
      } else {
        apiService.post(url, this.section, this.saveSuccess, this.saveError);
      }
    },

    onUpdateJSON(jsonData) {
      console.log("onUpdateJSON() ", jsonData);
      this.section.typeData = jsonData;
    },

    onUpdateMarkup(markup) {
      this.section.markup = markup;
    },

    onUpdateSection(section) {
      console.log("Section.onUpdateSection()")
      if (this.section) {
        this.section = section

        switch(this.section.type) {
          case 1:
            this.$refs.textView.setSection(this.section)
            break
          case 2:
            this.$refs.imageView.setSection(this.section)
            break
          case 3:
            this.$refs.imageTextView.setSection(this.section)
            break
          case 4:
            this.$refs.carouselView.setSection(this.section)
            break
          case 5:
            this.$refs.cardView.setSection(this.section)
            break
          case 6:
            this.$refs.cardsView.setSection(this.section)
            break
          case 7:
            this.$refs.videoView.setSection(this.section)
            break
          default:
            break
        }
      }
    },

    onUpdateImage(jsonData) {
      console.log("onUpdateImage() ", jsonData);
      this.section.typeData = jsonData;
    },

    saveSuccess(result) {
      this.section = result.data;
      router.go(-1);
    },

    saveError(error) {
      const notification = {
        title: "Error saving the Section",
        error: error,
      };
      store.commit("setResponseError", notification);
    },
  },
};
</script>

<style lang="scss" scoped>
.button {
  margin-top: 25px;
  margin-left: 20px;
}
</style>